.react-datepicker-wrapper {
    width: 100%;
  }
  
  .react-datepicker__input-container {
    width: 100%;
  }
  
  .react-datepicker__input-container input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
    transition: 0.5s;
  }
  
  .react-datepicker__input-container input:focus {
    outline: none !important;
    border: 3px solid #479791;
  }
  
  /* Calendar */
  .react-datepicker {
    font-family: Arial, Helvetica, sans-serif;
    border: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .react-datepicker__header {
    background-color: #f7f7f7;
    border-bottom: 1px solid #ddd;
  }
  
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    color: #333;
    margin: 0.166rem;
  }
  
  .react-datepicker__day:hover {
    background-color: #f0f0f0;
  }
  
  .react-datepicker__day--selected {
    background-color: #479791;
    color: white;
  }
  
  .react-datepicker__day--keyboard-selected {
    background-color: #367a68;
    color: white;
  }
  /* Navigation */
  .react-datepicker__navigation {
    background-color: transparent; /* Make the background transparent */
    border: none; /* Remove border */
    width: 30px;
    height: 30px;
    position: absolute;
    cursor: pointer;
  }
  
  .react-datepicker__navigation--previous {
    left: 10px;
  }
  
  .react-datepicker__navigation--next {
    right: 10px;
  }
  
  .react-datepicker__navigation::before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
  }
  
  .react-datepicker__navigation--previous::before {
    border-width: 8px 12px 8px 0;
    border-color: transparent #479791 transparent transparent;
    margin-left: 6px;
  }
  
  .react-datepicker__navigation--next::before {
    border-width: 8px 0 8px 12px;
    border-color: transparent transparent transparent #479791;
    margin-right: 6px;
  }
  