
span {
    height: 30px;
    font-size: 12px;
    padding: auto;
    margin-bottom: -15px;
    color: red;
    display: none;
    text-align: left;
}

input {
    border: 1px solid rgb(223, 207, 207);
    border-radius: 3px;
    width: 100%;
    height: 40px;
    font-size: 15px;
    color: grey;
    margin: 10px 0px;
    padding-left: 8px;
 }
 
input:invalid ~ .error {
    display: block;
    margin-bottom: 5px;
}

input[type="checkbox"] {
    border: none;
    width: 10px;
    height: 10px;
    margin: 0px;
    padding: 0px;
 }

input[type="file"] {
   display: none;
}

input[type="date"] {
    display: inline;
    width: 120px;
    height: 16px;
    margin-top: 0;
 }
 
textarea {
    border: 1px solid #b3a9a9;
    height: 100px;
    width: 80%;
   outline: none;
  font-size: 13px;
  color: #999999;
}
.custom-file-upload {
    display: block;
    font-size: 13px;
    color: white;
    width: 110px;
    height: 24px;
    padding-top: 7px;
    border: 1px solid #b3a9a9;
    border-radius: 20px;
    cursor: pointer;
    background: #15887c;
   
}

.custom-file-upload.hover {
    background: #147c5d;
   
  }