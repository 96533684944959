.profile-card{
    font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
    width: 320px;
    height: 480px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 1px 10px 1px #000;
    background: #fff;
    position: absolute;
    right: 40px;
    top: 40px;

}

.profile-header{
    width: 95%;
    height: 2vh;
    line-height: 1.3rem;
    text-align: center;
    border-radius: 10px;
    background: linear-gradient(to left,#e8f0fd,#e8f0fd);
    margin: 0 auto;
   
}
.profile-header p{
    font-size: 0.5rem;
    vertical-align: middle;
}

.profile-upper{
    width: 100%;
    margin: auto;
    padding: 0.3rem;
    
}

.profile-upper .profile-avatar{
    width: 100%;
    display:flex;
    justify-content: space-between;
    align-items: center;

}

.profile-avatar .left{
   display: flex;
   padding: 0 0.1rem;

}
.profile-avatar .left .image-container{
    display: grid;
    grid-template-columns: repeat(12,1fr);
    position: relative;
    align-items: center;
}

.profile-avatar .left img{
    max-width: 60%;
    border-radius: 50%;
    border: 1px solid #fff;
}

.profile-avatar .left .top{
    grid-column: 1 / span 8;
    grid-row: 1 / span 12 ;

}

.profile-avatar .left .bottom{
    grid-column: 4 / -2;
    grid-row: 4 / span 12;
}

.profile-avatar .right{
    flex-direction: column;
    margin: 0 -4rem;

}

.profile-avatar .right .profile-name{
    font-size: 0.8rem;
    font-weight: 500;
}
.profile-avatar .right .profile-email{
    font-size: 0.7rem;
    font-weight: 500;
}


.profile-upper .profile-info{
    max-width: 100%;
    height: 6vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.profile-middle{
    max-width: 100%;
    height: 6vh;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.profile-bottom{
    max-width: 100%;
    height: 6vh;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;

}
.profile-footer{
    max-width: 100%;
    background: #fff;
    display: flex;
    height: 6vh;
    justify-content: center;
    align-items: center;
}

.profile-footer p{
    text-align: center;
    font-size: 0.8rem;
}

